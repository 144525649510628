<template>
  <div
    :class="['statusBox', classes]"
    :style="{
      background: background,
      color: color,
      'box-shadow': boxShadow,
      '--tagColor': tagColor,
    }"
  >
    {{ statusTxt }}
  </div>
  <!-- <span>
        <TagSign :classes="classes" :background="background" :color='color' :boxShadow="boxShadow">
             <template v-slot:tagContent>{{statusTxt}}</template>
        </TagSign>
    </span> -->
</template>

<script>
export default {
  props: ["status", "statusTxt","classes"],
  data() {
    return {
      background: "",
      color: "",
      boxShadow: "",
      //   tagStyle: "",
      tagColor: "",
    };
  },
  watch: {
    status: function () {
      this.changeStatus();
    },
  },
  //   mounted() {
  //     this.changeStatus();
  //   },
  created() {
    this.changeStatus();
  },
  methods: {
    changeStatus() {
      console.log(this.status);
      
      switch (this.status) {
        case -1:
        case 20:
          // this.tagColor = "#fff";
          this.color = "#909399";
          // this.background = "#fff";
          // this.boxShadow = "rgb(203 200 200) 2px 1px 8px 1px";
          break;
        case 0:
        case 10:
          // this.background="#fece30"
          this.color = "#A67C0B";
          // this.tagColor = "#fece30";
          break;
        case 5:
        case 100:
          // this.background="#6eceb2"
          this.color = "#4AC672";
          // this.tagColor = "#6eceb2";
          break;
        default:
          // this.background="#688ce8"
          this.color = "#A67C0B";
          // this.tagColor = "#688ce8";
          break;
      }
    },
  },
};
</script>
<style scoped>
.statusBox {
  color: #909399;
  font-size: .13rem;
 
}
.isNoPosition{
  top: .14rem !important;
  right: .14rem !important;
}
</style>