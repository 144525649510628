<template>
  <div class="allOrderBox">
    <div class="inForBox">
      <div>
        <p class="iconBox">
          <svg class="icon" aria-hidden="true" v-if="item.sourceIcon || item.SourceIcon">
            <use :xlink:href="'#' + (item.sourceIcon || item.SourceIcon)" />
          </svg>
          <img src="../../../assets/images/defaultIcon.png" v-else />
        </p>
        <template v-if="item.supplierName || item.SourceCodeTxt">
          <p>{{ item.supplierName || item.SourceCodeTxt }}</p>
          <a v-if="item.CarLevel">{{ item.CarLevel }}</a>
          <a v-if="item.TypeTxt && item.TypeTxt != 'null' && item.TypeTxt != item.CarLevel">{{ item.TypeTxt }}</a>
          <a v-if="item.typeTxt">{{ item.typeTxt }}</a>
          <a v-if="item.TypeTxt != item.Rule">{{ item.Rule }}</a>
          <a class="channelTxt" v-if="item.channelTxt">{{ item.channelTxt }}</a>
        </template>
        <template v-else>
          <p>请填写用车需求</p>
        </template>
      </div>

      <div>
        <eventTag :statusTxt="item.statusTxt || item.ExtOrderStatusTxt" :status="item.status || item.Status"></eventTag>
      </div>
    </div>
    <p v-if="item.departureTime || item.itsItemData.orderTime || (item.Departure_time && item.Departure_time != 'null')" class="departure_time">
      <svg class="icon timeIcon" aria-hidden="true">
        <use xlink:href="#icon-shijian" />
      </svg>
      <template v-if="item.itsItemData && item.itsItemData.carRule == '14' && item.itsItemData.orderTime">{{ item.itsItemData.orderTime }}</template>
      <template v-if="item.Departure_time && item.Departure_time != 'null'">
        <template v-if="item.Departure_time.indexOf('T') > -1">{{ item.Departure_time | formatDate('yyyy-MM-dd hh:mm') }}</template>
        <template v-else>{{ item.Departure_time }}</template>
      </template>
      {{ item.departureTime | formatDate('yyyy-MM-dd hh:mm') }}
    </p>
    <template v-if="item.endName || item.Addr_end">
      <p>
        <i class="greenBc"></i>
        {{ item.startName || item.Addr_start }}
      </p>
      <p>
        <i class="orangeBc"></i>
        {{ item.endName || item.Addr_end }}
      </p>
    </template>
    <template v-if="item.carLevel">
      <p class="car_level">
        {{ item.carLevel }}
      </p>
    </template>
    <p class="shortp" v-if="item.passengerName">订单创建人 {{ item.passengerName }}</p>
    <p class="shortp" v-if="item.channel != 2 && $cookies.get('tenant') == 'msd'">EasyCarNO:{{ item.orderExtNum || item.ExtOrderNum || '暂无' }}</p>
    <div v-if="!item.ItsButtons" class="callbox" @click.stop="showCallBox(item)">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icondianhua-" />
      </svg>
    </div>
    <!-- 山德士订单确认/取消icon -->

    <div class="approvalIcon" v-if="['novartis'].includes($cookies.get('tenant'))&&item.ItemData">
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="JSON.parse(item.ItemData).OwnerConfirm == 1 ? '#icon-yiqueren' : JSON.parse(item.ItemData).OwnerConfirm == 2 ? '#icon-yijujue' : ''" />

      </svg>
    </div>

    <template v-if="item.ItsButtons">
      <div :class="['btnList', item.ItsButtons.length > 3 && 'gaodeBtns']" v-if="!this.SmartStorage.get('viewOnly')">
        <div class="dropDownBox" v-if="isDrop">
          <!-- <span @click.stop="copyData(item,1)">复制订单</span>
        <span @click.stop="copyData(item,2)">一键返程</span> -->

          <template v-if="item.ItsButtons.length > 3">
            <span v-for="(btn1, bindex1) in item.ItsButtons.slice(3, item.ItsButtons.length)" :key="bindex1" @click.stop="isloding && btnClick(item, btn1, bindex1)">{{ btn1.Txt }}</span>
          </template>
        </div>
        <div class="more-btn">
          <div v-if="item.ItsButtons.length > 3" @click.stop="isDrop = !isDrop">更多</div>
        </div>
        <div class="rest-btn">
          <a
            :class="[btn.Processor, item.ItsButtons.length > 3 ? 'btnLength3' : 'btnLength' + item.ItsButtons.length, !isloding && 'isloding', (btn.status == 1 || btn.status == 3) && 'survayed']"
            v-for="(btn, bindex) in item.ItsButtons.slice(0, 3)"
            :key="bindex"
            @click.stop="isloding && btnClick(item, btn, index)"
          >
            {{ btn.Txt }}
          </a>
        </div>
        <!-- <a :class="'btnLength' +(item.ItsButtons.length+1)">拨打电话</a> -->
      </div>
    </template>
  </div>
</template>

<script>
import eventTag from '@/views/common/eventTag';
export default {
  props: ['item', 'showCallBox', 'btnClick', 'isloding', 'dropDown', 'index', 'tabBar'],
  data() {
    return {
      boxShadow: '',
      background: '',
      color: '',
      isDrop: false
    };
  },
  watch: {
    dropDown: {
      handler(newValue, oldValue) {
        this.isDrop = false;
      }
    }
  },
  components: {
    eventTag
  },
  mounted() {
    document.addEventListener('click', this.handleClick);
  },
  methods: {
    handleClick(e) {
      let event = e || window.event;
      const target = event.target;
      let dropDownBox = document.getElementsByClassName('dropDownBox')[0];
      if (dropDownBox && !dropDownBox.contains(target)) {
        this.isDrop = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/msd/allCarOrder.scss';
.approvalIcon {
  position: absolute;
  right: 0.1rem;

  .icon {
    width: 0.4rem;
    height: 0.4rem;
  }
}
</style>
