import Vue from 'vue';
const choiseExpenseItem = {
    data() {
        return {
            listOn: {},
            backListOn: "",
            timeer:null,
            timeer1:null
        };
    },
    watch: {
        "$store.state.homeWait": function () {
          let homeWait = this.$store.state.homeWait;
          let _this=this;
          if (homeWait.type == "event") {
            this.scrollTop();
          } else {
            let listOn = _this.$store.state.homeListOn;
            this.backListOn = listOn.index || "";
            this.timeer1=setTimeout(function () {
              if (document.getElementById("listHref" + (listOn.index || 0))) {
                document.getElementById("listHref" + (listOn.index || 0)).click();
              }
            }, 500);
          }
        },
      },
      beforeDestroy(){
        clearTimeout(this.timeer);
        clearTimeout(this.timeer1);
      },
    methods: {
        stopSt(e) {
            e.stopPropagation();
        },
        choiseExpenseItems(index) {
            console.log(index);
            this.flag = index;
            this.iconColor = "#FFF";
            let _this = this;
            this.timeer=setTimeout(function () {
                _this.flag = -1;
                _this.iconColor = "#90939A";
            }, 500);
        },
        setListOn(index) {
            this.listOn = {
                index: index,
            };
            this.$store.commit("setValue", {
                key: "homeListOn",
                value: this.listOn,
            });
        },
    }
}
Vue.mixin(choiseExpenseItem)

export default choiseExpenseItem;