<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-26 10:35:48
-->
<template>
  <div>
    <iHeader
      backType="blank"
      :headStyle="{
        background: '#fff',
        color: '#13161B'
      }"
      :backEvent="true"
      @back-event="closeWebview()"
      :back="true"
    >
      <div slot="headText">
        <span class="head-txt">我的订单</span>
      </div>
    </iHeader>
    <div class="searchBox">
      <div class="prNum">
        <Svgs color="#ccc" classes="opChooseListall" name="iconsousuo" />
        <input :disabled="!SmartStorage.get('isAll')" v-model="prNum" placeholder="请输入PR编号" @input="changeModel" />
      </div>
      <div class="dataRangeBox" @click="showDataRange = true">
        <template v-if="dataRange[0]">{{ dataRange[0] + '~' + dataRange[1] }}</template>
        <div class="clearData" @click.stop="clearData">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconguanbi" />
          </svg>
        </div>
      </div>
    </div>
    <ScrollBox class="opOrderListScroll1" @on-top="scrollTop" :loadType="loadType">
      <template v-slot:scrollBox>
        <div v-if="dataList.length" class="contentBox1">
          <ul>
            <li v-for="(item, index) in dataList" :key="index" :class="['opHomeOn', item.channel == 2 && 'isCity']" :id="'opOrderList'" @click="viewOrder(item, index)">
              <orderListPart :item="item" :showCallBox="showCallBox"></orderListPart>
            </li>
          </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <Calendar :color="'var(--themeColor)'" :min-date="minDate" v-model="showDataRange" type="range" :default-date="defaultData" @confirm="onConfirm" />
    <transition name="slide-fade">
      <callBox v-if="isShowCallBox" :choiseItem="choiseItem" :showCallBox="showCallBox"></callBox>
    </transition>
  </div>
</template>

<script>
import orderListPart from '@/views/common/msd/orderListPart';
import callBox from '@/views/common/msd/callBox';
import vueFilter from '@/views/controllers/filter/vuefilter.js';
import { Calendar } from 'vant';
import submitOrder from '@/views/controllers/submitOrder.js';
import choiseExpenseItem from '@/views/controllers/choiseExpenseItem.js';
import 'vant/lib/index.css';
export default {
  mixins: [submitOrder, choiseExpenseItem],
  data() {
    return {
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      isFirst: true,
      isShowCallBox: false,
      dataList: [],
      dataRange: [],
      defaultData: [],
      minDate: new Date(2010, 1, 1),
      showDataRange: false,
      prNum: '',
      pageIndex: 1,
      pageSize: 10,
      loadType: '',
      choiseItem: {}
    };
  },
  components: {
    Calendar,
    orderListPart,
    callBox
  },
  async created() {
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on('closeWebview', data => {
      this.closeWebview();
    });
    this.prNum=this.SmartStorage.get('isAll') ? '' : this.SmartStorage.get('extNum');
  },
  activated() {
    //keepAlive导致返回列表无刷新处理
    this.MergePaging();
  },
  mounted() {
    let amonth = vueFilter.formatDate(this.getMonthAgo(), 'yyyy-MM-dd');
    this.defaultData = [new Date(amonth), new Date()];
    this.dataRange = [amonth, vueFilter.formatDate(new Date(), 'yyyy-MM-dd')];
    this.MergePaging();
  },
  methods: {
    clearData() {
      this.dataRange = [];
      this.MergePaging(true);
    },
    changeModel() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      let _this = this;
      this.timer = setTimeout(function() {
        _this.MergePaging(true);
      }, 500);
    },
    viewOrder(item, index) {
      // item.itsItemData = JSON.parse(item.ItemData);
      this.setListOn(index);
      if (item.channel == 2) {
        this.SmartStorage.set('carOrder', item);
        let type = item.status > 50 ? '/carAccount' : '/carDetail';
        this.$router.push({
          path: type,
          query: {
            isFromeAll: true
          }
        });
      } else {
        item.SourceCode = item.sourceCode;
        if (this.isGoh5(item, false, item.status != 0 && item.status != -1)) {
          item.ProposalId = item.proposalId;
          item.ItemId = item.itemId;
          this.SmartStorage.set('orderDetail', item);
          this.$router.push({
            path: '/platformPriceCoupon',
            query: {
              proposalId: item.proposalId,
              isFromeAll: true
            }
          });
        }
      }
    },
    getMonthAgo() {
      let lastMonthToday = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
      let lastMonthYear = lastMonthToday.getFullYear();
      let lastMonth = lastMonthToday.getMonth() + 1;
      let lastMonthDay = lastMonthToday.getDate < 10 ? '0' + lastMonthToday.getDate : lastMonthToday.getDate();
      let data = vueFilter.formatDate(new Date(), 'yyyy-MM-dd');
      let index = data.lastIndexOf(' ');
      let time = data.substring(index + 1, data.length);
      let lastMonthKsrq = lastMonthYear + '/' + lastMonth + '/' + lastMonthDay;
      return lastMonthKsrq;
    },
    redirectRouteListeners() {
      this.SmartStorage.set('routeListeners', ['closeWebview']);
    },
    closeWebview() {
      const container = this.$cookies.get('container');
      console.log('container', container);
      switch (container) {
        case 'browser':
          this._browserBack();
          break;
        case 'h5':
          this._h5Back();
          break;
        case 'native':
        default:
          this.closeAppWithReload();
      }
    },
    showCallBox(item) {
      this.choiseItem = item;
      this.isShowCallBox = !this.isShowCallBox;
    },
    onSearch() {},
    scrollTop() {
      this.loadType = 'top';
      this.MergePaging(true);
    },
    MergePaging(clear) {
      let params = {
        ExtNum: this.prNum
      };
      if (this.dataRange[0]) {
        params.StartTime = this.dataRange[0];
        params.EndTime = this.dataRange[1];
      }
      let _this = this;
      this.services.MergePaging(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          _this.dataList = res.content.rows || [];
          _this.loadType = '';
        }
      });
    },
    onConfirm(value) {
      this.showDataRange = false;
      this.dataRange = [vueFilter.formatDate(value[0], 'yyyy-MM-dd'), vueFilter.formatDate(value[1], 'yyyy-MM-dd')];
      this.MergePaging(true);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '@/assets/msd/allCarOrder.scss'
</style>
