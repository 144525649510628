<template>
  <div class="callBoxMain" @click="showCallBox">
      <div class="callList">
          <ul>
              <li v-if="choiseItem.channel != 2" @click.stop="ihtml.telCall(choiseItem.passengerPhone||choiseItem.Passenger_phone)">
                  乘车人：{{choiseItem.passengerName||choiseItem.Passenger_name}}({{choiseItem.passengerPhone||choiseItem.Passenger_phone||'暂无'}})
              </li>
              <li @click.stop="ihtml.telCall(choiseItem.driverPhone||choiseItem.orderData&&choiseItem.orderData.driverPhone)" v-if="choiseItem.driverName||choiseItem.orderData&&choiseItem.orderData.driverPhone">
                 {{choiseItem.channel == 2?choiseItem.supplierName:'司机：'}}  {{choiseItem.driverName||choiseItem.orderData&&choiseItem.orderData.driverName}} ({{choiseItem.driverPhone||choiseItem.orderData&&choiseItem.orderData.driverPhone}})
              </li>
          </ul>
      </div>
      <div class="canselBtn" @click.stop="showCallBox">
          取消
      </div>
  </div>
</template>

<script>
export default {
props: ["showCallBox","choiseItem"],
}
</script>

<style lang="sass" scoped>
@import '@/assets/msd/allCarOrder.scss'
</style>